@import "bootstrap";
@import "fontawesome";

//--- Added by Ramesh
// Colors
$red-color:           #FF3636 !default;
$red-color-dark:      #B71C1C !default;
$orange-color:        #F29105 !default;
$blue-color:          #0076df !default;
$blue-color-dark:     #00369f !default;
$cyan-color:          #2698BA !default;
$light-cyan-color:    lighten($cyan-color, 25%);
$green-color:         #00ab37 !default;
$green-color-lime:    #B7D12A !default;
$green-color-dark:    #009f06 !default;
$green-color-light:   #ddffdd !default;
$green-color-bright:  #11D68B !default;
$purple-color:        #B509AC !default;
$light-purple-color:  lighten($purple-color, 25%);
$pink-color:          #f92080 !default;
$pink-color-light:    #ffdddd !default;
$yellow-color:        #efcc00 !default;

$grey-color:          #828282 !default;
$grey-color-light:    lighten($grey-color, 40%);
$grey-color-dark:     #1C1C1D;

$white-color: #ffffff !default;
$black-color: #000000 !default;


// Theme colors

$code-bg-color-light:     rgba($purple-color, 0.05);
$code-bg-color-dark:      #2c3237 !default;

:root {
  --global-bg-color: #{$white-color};
  --global-code-bg-color: #{$code-bg-color-light};
  --global-text-color: #{$black-color};
  --global-text-color-light: #{$grey-color};
  --global-theme-color: #{$purple-color};
  --global-hover-color: #{$purple-color};
  --global-footer-bg-color: #{$grey-color-dark};
  --global-footer-text-color: #{$grey-color-light};
  --global-footer-link-color: #{$white-color};
  --global-distill-app-color: #{$grey-color};
  --global-divider-color: rgba(0,0,0,.1);

  .fa-sun {
    display : none;
  }
  .fa-moon {
    padding-left: 10px;
    padding-top: 12px;
    display : block;
  }
}

// END --- Added by Ramesh

img { margin-bottom: 24px;
     margin-top: 17px;
    border-radius:2%;
}


body {
	padding-top: 70px;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}

// josh experimenting with logo image in navbar
.logo-image{
  width: 66px;
  height: 66px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -27px;
}


/* Top navigation bar */


p.byline {
	text-align: center;
	font-size: 18px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}


pubtit {
	font-weight: bold;
}

firstp {
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px; /* or 950px */
}

/* Home, News */
#newsid p {
	text-align: left;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 6px;
	margin-bottom: 6px;
}

/* Team & Publications */
#gridid img { margin: 16px 22px 6px 0; border-radius:10%; box-shadow: 2px 2px 5px #888888 }
#homeid img { margin: 0 0 0 0; border-radius:5%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:1%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }


/* Grid overrides */
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
	padding-left: 16px;
	padding-right: 16px;
}

/* Grid overrides */
.col-sm-4 {
	padding-left: 26px;
	padding-right: 26px;
}

.col-sm-4.footer {
  padding-left: 16px;
	padding-right: 16px;
  margin-right: 165px;
}

.col-sm-5.footer {
  padding-left: 16px;
	padding-right: 16px;
  margin-right: 80px;
}

/* Carusel */

.carousel {
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.publications {
  margin-top: 2rem;
  // margin-right: -11rem;
  h1 {
    color: var(--global-theme-color);
    font-size: 2rem;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  h2 {
    margin-bottom: 1rem;
    span {
      font-size: 1.5rem;
    }
  }
  h2.year {
    color: var(--global-divider-color);
    border-top: 1px solid var(--global-divider-color);
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -2rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: 0;

    li {
      margin-bottom: 1rem;
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: var(--global-theme-color);
          padding-left: 1rem;
          padding-right: 1rem;
          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .award {
          color: var(--global-theme-color) !important;
          border: 1px solid var(--global-theme-color);
        }
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed var(--global-theme-color);
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      .links {
        a.btn {
          color: var(--global-text-color);
          border: 1px solid var(--global-text-color);
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          &:hover {
            color: var(--global-theme-color);
            border-color: var(--global-theme-color);
          }
        }
      }
      .bibhidden {
        font-size: 1.2rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        transition-property: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 1em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .bibhidden.open {
          max-height: 100em;
          transition-property: 0.15s ease;
          -moz-transition: 0.15s ease;
          -ms-transition: 0.15s ease;
          -o-transition: 0.15s ease;
          transition: all 0.15s ease;
      }
      div.abstract.bibhidden {
        border: dashed 1px var(--global-bg-color);
      }
      div.abstract.bibhidden.open {
        border-color: var(--global-text-color);
      }
    }
  }
}
